import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import "../../assest/css/index.css"
import "../../assest/css/index.min.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import { MdCastForEducation } from "react-icons/md";
interface signUpProps { }
const Feature = (props: signUpProps) => {
    return (
        <>
            <div className="features">
                <h1 className="page_main-header text-center">Our Approach</h1>
                <Container>
                    <ul className="features_list d-md-flex justify-content-center flex-wrap p-0">
                        <li className="features_list-item col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="card_media">
                                        <i className="icon-globe-solid icon"></i>
                                    </div>
                                    <div className="card_main">
                                        <h5 className="card_main-title">Courses for All Grades:</h5>
                                        <p className="card_main-text">
                                            Whether your child is just starting their academic journey or preparing for
                                            college entrance exams, we have courses designed to meet their needs. From foundational
                                            concepts to advanced topics, we cover it all.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="features_list-item col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="card_media">
                                        <i className="icon-headset-solid icon"></i>
                                    </div>
                                    <div className="card_main">
                                        <h5 className="card_main-title">Personalized Support:</h5>
                                        <p className="card_main-text">
                                            We believe in the power of personalized learning. Our experienced
                                            educators work closely with each student to identify their strengths, areas for improvement, and
                                            learning style, tailoring our approach to suit their individual needs.                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="features_list-item col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="card_media">
                                    <MdCastForEducation  className="icon-globe-solid icon" />
                                    </div>
                                    <div className="card_main">
                                        <h5 className="card_main-title">Acceleration Programs</h5>
                                        <p className="card_main-text">
                                            We understand that some students may need to accelerate their
                                            learning to reach their goals. Our acceleration programs provide advanced coursework and
                                            enrichment opportunities to challenge students and help them excel.                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Container>
            </div>
        </>
    )
}

export default Feature
