import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import Navigation from "../Navbar/Navigation"
import Footer from "../Footer/Footer"
const MainWrapper = () => {
    return (
        <>
        <Container fluid className="p-0">
            <Row>
                <Col md={12} className="p-0">
                <Navigation/>
                </Col>
                <Col md={12} className="p-0">
                <Outlet/>
                </Col>
                <Col>
                <Footer/>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default MainWrapper