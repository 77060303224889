import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Navbar, Nav, Pagination, Button } from "react-bootstrap"
import "../../assest/css/blog.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import blogimage from "../../assest/img/blog-1.jpeg"
import blogimage2 from "../../assest/img/blog-2.jpeg"
import galleryimage from "../../assest/img/gallery_01.jpg"
import { Link } from "react-router-dom";
import { FaArrowTurnUp } from "react-icons/fa6";
interface signUpProps { }
const Blog = (props: signUpProps) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Button
                className={`scroll-to-top ${isVisible ? 'visible' : 'hidden'}`}
                onClick={scrollToTop}
            >
                <FaArrowTurnUp />
            </Button>
            <main>
                <section className="blog padding-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="blog-items">
                                    <div className="blog-item blog-item-classes">
                                        <div className="blog-image">
                                            <a href="single.html"><img src={blogimage} alt="blog image" className="img-responsive" /></a>
                                        </div>
                                        <div className="blog-content">
                                            <h4>Exploring New Frontiers: Innovations in Math Education for Kids</h4>
                                            <p>In today's rapidly evolving world, mathematical literacy is more crucial than ever for children to thrive in various fields. Fortunately, innovative approaches to math education are continually emerging, making learning math both engaging and accessible for young learners.</p>
                                            <Link to="/singleblog1" className="button-default">Read More</Link>
                                        </div>
                                    </div>{/* blog item */}
                                    <div className="blog-item blog-item-classes">
                                        <div className="blog-image">
                                            <a href="single.html"><img src={blogimage2} alt="blog image" className="img-responsive" /></a>
                                        </div>
                                        <div className="blog-content">
                                            <h4>Unlocking Creativity: Introducing Kids to the World of Coding</h4>
                                            <p>In today's digital age, where technology is evolving at an unprecedented pace, coding has become a vital skill for children to learn. From developing problem-solving abilities to fostering creativity and innovation, coding offers a plethora of benefits that extend far beyond the confines of the computer screen. </p>
                                            <Link to="/singleblog2" className="button-default">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Blog
