import "../../assest/css/index.css"
import "../../assest/css/index.min.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import { useLottie } from "lottie-react";
import * as anim from "../../assest/animations/hero.json";

const Animation = ({ animData, width}: { animData: any, width: number} ) => {
  const containerStyle = {
    width: `${width}%`,
};

    const options = {
        animationData: animData,
        loop: true,
      };
    
      const { View } = useLottie(options);
      return  <div style={containerStyle}>{View}</div>;
}

export default Animation
