import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Button } from "react-bootstrap"
import "../../assest/css/contacts.css"
import "../../assest/css/contacts.min.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import { Link } from "react-router-dom"
import { FaArrowTurnUp } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { sendEmail } from '../node-mail';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
interface signUpProps { }
const Contact = (props: signUpProps) => {
    const [phone, setPhone] = useState("");
    const [isChecked, setIsChecked] = React.useState(true); // or false if unchecked by default

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Button
                className={`scroll-to-top ${isVisible ? 'visible' : 'hidden'}`}
                onClick={scrollToTop}
            >
                <FaArrowTurnUp />
            </Button>
            <header className="page">
                <div className="page_breadcrumbs">
                    <div className="container">
                        <ul className="page_breadcrumbs-list d-flex flex-wrap align-items-center p-0 m-0">
                            <li className="list-item">
                                <Link to="/" className="nav-item">Home</Link>
                            </li>

                            <li className="list-item">
                                <Link to="/about" className="nav-item">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="page_main">
                    <div className="underlay"></div>
                    <div className="container">
                        <div className="content-wrapper">
                            <h1 className="page_main-header">Contact Us</h1>
                            <p className="page_main-text">
                                For all inquiries, feedback, or collaboration opportunities, please don't hesitate to reach out to us through our contact form or directly via email. We value your input and are committed to providing swift and helpful responses to any questions or concerns you may have. Your engagement is vital to us, and we look forward to hearing from you soon!
                            </p>
                        </div>
                        <ul className="page_main-list d-sm-flex flex-wrap justify-content-center py-5 p-0">
                            <li className="page_main-list_item">
                                <div className="wrapper">
                                    <div className="content d-flex justify-content-sm-center align-items-center">
                                        <FaWhatsapp className="icon-phone-solid icon" />
                                        <div className="block d-flex flex-column">
                                            <a className="content_link text" href="tel:+91 82787 17982">+91 82787 17982</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="page_main-list_item">
                                <div className="wrapper">
                                    <div className="content d-flex justify-content-sm-center align-items-center">
                                        <i className="icon-envelope icon"></i>
                                        <div className="block d-flex flex-column">
                                            <a className="content_link text" href="mailto: wholesquare.in@gmail.com">wholesquare.in@gmail.com</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            {/* contact-content-start */}
            <main className="contacts">
                <section className="contacts_form">
                    <div className="container">
                        <div className="contacts_form-header">
                            <h2 className="contacts_form-header_title">Our experts will help you</h2>
                            <p className="contacts_form-header_text text">
                                If you have questions about the format or do not know what to choose, leave your request: we will be happy to
                                answer all your questions.
                            </p>
                        </div>
                        <form className="contacts_form-form">
                            <div className="contacts_form-form_wrapper d-sm-flex justify-content-between">
                                <input className="field required" type="text" data-type="email" placeholder="Email" id="contactsEmail" />
                                <input className="field required" type="text" placeholder="Name" id="contactsName" />
                            </div>
                            <div className="contacts_form-form_wrapper d-sm-flex justify-content-between gap-3 ">
                                <input type='text' className='d-none' value={phone} id='contactsTel'/>
                                <PhoneInput
                                    country={"eg"}
                                    // enableSearch={true}
                                    value={phone}
                                    onChange={(country, e, phone) => {
                                        setPhone(phone?.target.value)}}
                                    data-type="tel"
                                    placeholder="Phone Number" // Added placeholder attribute
                                />
                                <input className="field required" type="text" placeholder="Subject" id="contactsSubject" />
                            </div>
                            <div className="contacts_form-form_wrapper d-sm-flex justify-content-between">

                            </div>
                            <textarea
                                className="field required"
                                placeholder="Your message here"
                                data-type="message"
                                id="contactsMessage"
                            ></textarea>

                        </form>
                        <div className="contacts_form-form_footer">
                            <button className="btn btn--gradient" onClick={sendEmail}>
                                <span className="text">Send a Message</span>
                            </button>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Contact
