import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Tab, Nav, Collapse, Button } from "react-bootstrap"
import "../../assest/css/pricing.css"
import "../../assest/css/pricing.min.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import * as scene from "../../assest/animations/scene.json"
import DefaultAnimation from "../Animation/Animation"
import { FaArrowTurnUp } from "react-icons/fa6";
import FAQ from '../Faq/faq';
interface signUpProps { }
const Price = (props: signUpProps) => {
    const [activeTab, setActiveTab] = useState('daily');

    const handleTabChange = (tabKey: string | null) => {
        if (tabKey) {
            setActiveTab(tabKey);
        }
    }
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Button
                className={`scroll-to-top ${isVisible ? 'visible' : 'hidden'}`}
                onClick={scrollToTop}
            >
                <FaArrowTurnUp />
            </Button>
            <main>
                <div className="underlay"></div>
                <section className="hero">
                    <div className="container d-lg-flex align-items-center">
                        <div className="hero_main col-lg-6">
                            <p className="hero_main-text">
                                Are you ready to provide your child with the best educational support? Choose the plan that fits
                                your child&#39;s learning needs and kick-start their journey towards academic success. Whether your
                                child is a curious explorer or a determined achiever, we offer plans tailored to their growth and
                                development. Join our supportive community of parents and educators today, and give your child
                                the gift of a brighter future. Don&#39;t hesitate - invest in your child&#39;s education now and watch them
                                thrive!
                            </p>
                        </div>
                        <div className="hero_media">
                            <div className="toggle-btn-container">
                                <DefaultAnimation
                                    animData={scene}
                                    width={100}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {/* pricing-plan */}
                <section className="pricing py-5">
                    <Container>
                        <div className="pricing_header">
                            <h2 className="pricing_header-title">Pricing plans for subscribers</h2>
                        </div>
                        <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
                            <Nav className="pricing_nav d-flex flex-nowrap align-items-center justify-content-center" role="tablist">
                                <Nav.Item>
                                    <Nav.Link eventKey="daily">
                                        <span className="nav-link_text">Math</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="monthly">
                                        <span className="nav-link_text">CODING</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="annual">
                                        <span className="nav-link_text">SCIENCE</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="combo">
                                        <span className="nav-link_text">COMBO</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="daily">
                                    <ul className="pricing_list d-md-flex flex-wrap p-0">
                                        <li className="pricing_list-card col-md-6 col-lg-6">
                                            <div className="card-wrapper">
                                                <div className="top top--advanced">
                                                    <h5 className="top_title">Grade K-5</h5>
                                                </div>
                                                <div className="main">
                                                    <ul className="main_list">
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">3 months billing- 90$/ month</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">Bill Renew after 3 months</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">If paid for 6 months- 80$/ month</p>
                                                        </li>
                                                    </ul>
                                                    {/* <a className="main_btn btn btn--gradient" href="#">
                                                        <span className="text">Get This Plan</span>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="pricing_list-card col-md-6 col-lg-6">
                                            <div className="card-wrapper">
                                                <div className="top top--corporate">
                                                    <h5 className="top_title">GRADE 6-10</h5>
                                                </div>
                                                <div className="main">
                                                    <ul className="main_list">
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">3 months billing- 110$/ month</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">Bill Renew after 3 months</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">If paid for 6 months- 95$/ month</p>
                                                        </li>
                                                    </ul>
                                                    {/* <a className="main_btn btn btn--gradient" href="#">
                                                        <span className="text">Get This Plan</span>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="monthly">
                                    <ul className="pricing_list d-md-flex flex-wrap p-0">
                                        <li className="pricing_list-card col-md-6 col-lg-6">
                                            <div className="card-wrapper">
                                                <div className="top top--advanced">
                                                    <h5 className="top_title">Grade K-5</h5>
                                                </div>
                                                <div className="main">
                                                    <ul className="main_list">
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">3 months billing- 95$/ month</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">Bill Renew after 3 months</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">If paid for 6 months- 80$/ month</p>
                                                        </li>
                                                    </ul>
                                                    {/* <a className="main_btn btn btn--gradient" href="#">
                                                        <span className="text">Get This Plan</span>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="pricing_list-card col-md-6 col-lg-6">
                                            <div className="card-wrapper">
                                                <div className="top top--corporate">
                                                    <h5 className="top_title">GRADE 6-10</h5>
                                                </div>
                                                <div className="main">
                                                    <ul className="main_list">
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">3 months billing- 110$/ month</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">Bill Renew after 3 months</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">If paid for 6 months- 95$/ month</p>
                                                        </li>
                                                    </ul>
                                                    {/* <a className="main_btn btn btn--gradient" href="#">
                                                        <span className="text">Get This Plan</span>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="annual">
                                    <ul className="pricing_list d-md-flex flex-wrap p-0">
                                        <li className="pricing_list-card col-md-6 col-lg-6">
                                            <div className="card-wrapper">
                                                <div className="top top--advanced">
                                                    <h5 className="top_title">Grade K-5</h5>
                                                </div>
                                                <div className="main">
                                                    <ul className="main_list">
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">3 months billing- 80$/ month</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">Bill Renew after  3 months</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">If paid for 6 months- 75$/ month</p>
                                                        </li>
                                                    </ul>
                                                    {/* <a className="main_btn btn btn--gradient" href="#">
                                                        <span className="text">Get This Plan</span>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="pricing_list-card col-md-6 col-lg-6">
                                            <div className="card-wrapper">
                                                <div className="top top--corporate">
                                                    <h5 className="top_title">GRADE 6-10</h5>
                                                </div>
                                                <div className="main">
                                                    <ul className="main_list">
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">3 months billing- 90$/ month</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">Bill Renew after 3 months</p>
                                                        </li>
                                                        <li className="main_list-item d-flex">
                                                            <i className="icon-check icon" />
                                                            <p className="text">If paid for 6 months- 80$/ month</p>
                                                        </li>
                                                    </ul>
                                                    {/* <a className="main_btn btn btn--gradient" href="#">
                                                        <span className="text">Get This Plan</span>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="combo">
                                    <ul className="pricing_list d-md-flex flex-wrap p-0">
                                        <li className="pricing_list-card col-md-6 col-lg-6">
                                            <div className="card-wrapper p-0">
                                                <div className="top top--advanced">
                                                    <h5 className="top_title">IF STUDENT OPT FOR MATH + CODING - 170$/ MONTH</h5>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="pricing_list-card col-md-6 col-lg-6">
                                            <div className="card-wrapper p-0">
                                                <div className="top top--corporate">
                                                    <h5 className="top_title">IF STUDENT OPT FOR MATH + CODING+ SCIENCE - 225$/ MONTH</h5>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Container>
                </section>
                {/* faq section */}
                <FAQ />
            </main >
        </>
    )
}

export default Price
