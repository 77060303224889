import React, { useState } from 'react';
import { Container, Collapse } from "react-bootstrap";
import DefaultAnimation from "../Animation/Animation";
import * as phone from "../../assest/animations/phone.json";
import * as sphere from "../../assest/animations/sphere.json";

interface FAQProps { }

const FAQ: React.FC<FAQProps> = () => {
    const [openItem2, setOpenItem2] = useState(false);
    const [openItem3, setOpenItem3] = useState(false);
    const [openItem4, setOpenItem4] = useState(false);
    const [openItem5, setOpenItem5] = useState(false);
    const [openItem6, setOpenItem6] = useState(false);
    const [openItem7, setOpenItem7] = useState(false);
    const [openItem8, setOpenItem8] = useState(false);

    const handleItemClick = (item: number) => {
        setOpenItem2(item === 2 ? !openItem2 : false);
        setOpenItem3(item === 3 ? !openItem3 : false);
        setOpenItem4(item === 4 ? !openItem4 : false);
        setOpenItem5(item === 5 ? !openItem5 : false);
        setOpenItem6(item === 6 ? !openItem6 : false);
        setOpenItem7(item === 7 ? !openItem7 : false);
        setOpenItem8(item === 8 ? !openItem8 : false);
    };

    return (
        <>
            <section className="faq">
                <div className="phone">
                    <div className="toggle-btn-container">
                        <DefaultAnimation animData={phone} width={100} />
                    </div>
                </div>
                <div className="sphere">
                    <div className="toggle-btn-container">
                        <DefaultAnimation animData={sphere} width={100} />
                    </div>
                </div>
                <Container>
                    <div className="faq_accordion" id="faq_accordion">
                        <div className="faq_accordion-item">
                            <div className="item-wrapper">
                                <h4
                                    className="faq_accordion-item_header d-flex justify-content-between align-items-center"
                                    onClick={() => handleItemClick(2)}
                                    aria-expanded={openItem2}
                                >
                                    <span className="text">What grades do you offer courses for?</span>
                                    <span className={`icon ${openItem2 ? 'transform' : ''}`}></span>
                                </h4>
                                <Collapse in={openItem2}>
                                    <div id="item-2" className="accordion-collapse collapse">
                                        <div className="faq_accordion-item_body">
                                            We offer courses for students of all grades, from kindergarten to high school. Whether your child
                                            is just starting their academic journey or preparing for college entrance exams, we have programs
                                            designed to meet their needs.
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        {/* Add more FAQ items */}
                        <div className="faq_accordion-item">
                            <div className="item-wrapper">
                                <h4
                                    className="faq_accordion-item_header d-flex justify-content-between align-items-center"
                                    onClick={() => handleItemClick(3)}
                                    aria-expanded={openItem3}
                                >
                                    <span className="text">How do I enroll my child in a course?</span>
                                    <span className={`icon ${openItem3 ? 'transform' : ''}`}></span>
                                </h4>
                                <Collapse in={openItem3}>
                                    <div id="item-3" className="accordion-collapse collapse">
                                        <div className="faq_accordion-item_body">
                                            Enrolling with us is easy! Simply visit our website and follow the straightforward enrollment
                                            process. Once enrolled, you can choose the course that best fits your child&#39;s needs and schedule.
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        {/* Add more FAQ items */}
                        <div className="faq_accordion-item">
                            <div className="item-wrapper">
                                <h4
                                    className="faq_accordion-item_header d-flex justify-content-between align-items-center"
                                    onClick={() => handleItemClick(4)}
                                    aria-expanded={openItem4}
                                >
                                    <span className="text">Are your instructors qualified?</span>
                                    <span className={`icon ${openItem4 ? 'transform' : ''}`}></span>
                                </h4>
                                <Collapse in={openItem4}>
                                    <div id="item-4" className="accordion-collapse collapse">
                                        <div className="faq_accordion-item_body">
                                            Absolutely. Our instructors are highly qualified professionals with years of teaching experience.
                                            They undergo rigorous training and adhere to our standards of excellence to ensure the highest
                                            quality instruction for our students.
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        {/* Add more FAQ items */}
                        <div className="faq_accordion-item">
                            <div className="item-wrapper">
                                <h4
                                    className="faq_accordion-item_header d-flex justify-content-between align-items-center"
                                    onClick={() => handleItemClick(5)}
                                    aria-expanded={openItem5}
                                >
                                    <span className="text">What subjects do you offer courses for?</span>
                                    <span className={`icon ${openItem5 ? 'transform' : ''}`}></span>
                                </h4>
                                <Collapse in={openItem5}>
                                    <div id="item-5" className="accordion-collapse collapse">
                                        <div className="faq_accordion-item_body">
                                            We offer courses in a wide range of subjects, including but not limited to Mathematics, Science
                                            and Coding. Whatever your child&#39;s interests or academic needs, we have a course for them.
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        {/* Add more FAQ items */}
                        <div className="faq_accordion-item">
                            <div className="item-wrapper">
                                <h4
                                    className="faq_accordion-item_header d-flex justify-content-between align-items-center"
                                    onClick={() => handleItemClick(6)}
                                    aria-expanded={openItem6}
                                >
                                    <span className="text">What learning format do you offer?</span>
                                    <span className={`icon ${openItem6 ? 'transform' : ''}`}></span>
                                </h4>
                                <Collapse in={openItem6}>
                                    <div id="item-6" className="accordion-collapse collapse">
                                        <div className="faq_accordion-item_body">
                                            We offer flexible learning options, including in-person classes, live online sessions, and self-
                                            paced learning modules. You can choose the format that best suits your child&#39;s learning style and
                                            schedule.
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        {/* Add more FAQ items */}
                        <div className="faq_accordion-item">
                            <div className="item-wrapper">
                                <h4
                                    className="faq_accordion-item_header d-flex justify-content-between align-items-center"
                                    onClick={() => handleItemClick(7)}
                                    aria-expanded={openItem7}
                                >
                                    <span className="text">How can I track my child&#39;s progress in the course?</span>
                                    <span className={`icon ${openItem7 ? 'transform' : ''}`}></span>
                                </h4>
                                <Collapse in={openItem7}>
                                    <div id="item-7" className="accordion-collapse collapse">
                                        <div className="faq_accordion-item_body">
                                            We provide regular progress reports detailing your child&#39;s performance, including areas of
                                            improvement and achievements. Additionally, our instructors are always available to discuss your
                                            child&#39;s progress and address any concerns you may have.
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        {/* Additional FAQ items */}
                        <div className="faq_accordion-item">
                            <div className="item-wrapper">
                                <h4
                                    className="faq_accordion-item_header d-flex justify-content-between align-items-center"
                                    onClick={() => handleItemClick(8)}
                                    aria-expanded={openItem8}
                                >
                                    <span className="text">Do you offer any discounts or promotions?</span>
                                    <span className={`icon ${openItem8 ? 'transform' : ''}`}></span>
                                </h4>
                                <Collapse in={openItem8}>
                                    <div id="item-8" className="accordion-collapse collapse">
                                        <div className="faq_accordion-item_body">
                                            Yes, we occasionally offer discounts and promotions for our courses. Be sure to check our
                                            website regularly or subscribe to our newsletter to stay updated on the latest offers.
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default FAQ;
