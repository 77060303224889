import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, Col, Row, Accordion, Card, Button, Collapse, ListGroup } from "react-bootstrap"
import "../../assest/css/preload.min.css"
import "../../assest/css/about.css"
import "../../assest/css/about.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import logo from "../../assest/img/logo.png"
import { Link } from "react-router-dom"
import aboutimage from "../../assest/img/about-image.webp"
import Feature from '../FeatureCard/Feature';
import dummyimage from "../../assest/img/dummy-image.webp"
import * as phone from "../../assest/animations/phone.json"
import * as sphere from "../../assest/animations/sphere.json"
import DefaultAnimation from "../Animation/Animation"
import { FaArrowTurnUp } from "react-icons/fa6";
import classimage from "../../assest/img/class-image.jpg"
import FAQ from '../Faq/faq';
interface signUpProps { }
const About = (props: signUpProps) => {
    const items = ['Experience and Expertise:With four years of experience in the education sector, we bring a wealth of knowledge and expertise to our programs. Our team of experienced instructors is dedicated to providing top-notch education and support to every student.', 'Proven Results: Our track record speaks for itself. We have helped countless students improve their grades, excel in their studies, and achieve their academic goals. When you choose Whole Square, you can trust that you are in good hands.', 'Commitment to Excellence: At Whole Square, excellence is our standard. We are committed to providing the highest quality education and support to every student, ensuring '];
    const item = ['Frequency: We offer 2 classes per week, each lasting for 1 hour, ensuring regular and consistent engagement with the subject matter.', 'Doubt Resolution Sessions: Additionally, we provide a 30-minute doubt resolution session every week, where students can clarify any questions or concepts they find challenging.', 'Comprehensive Learning Experience: Our classes are not limited to lectures alone. Alongside the teaching sessions, students engage in practice skills, assignments, and periodic tests to reinforce learning and track progress effectively.', 'Targeted Audience: Our services cater to students from Kindergarten to Grade 12, covering a wide range of subjects and topics to support academic growth at every stage of education.', 'Expert Educators: Our team comprises teaching experts who bring years of experience and expertise in their respective fields. They are dedicated to providing personalized guidance and support to help students excel academically.']

    const [openItem2, setOpenItem2] = useState(false);
    const [openItem3, setOpenItem3] = useState(false);

    const handleItemClick = (item: number) => {
        setOpenItem2(item === 2 ? !openItem2 : false);
        setOpenItem3(item === 3 ? !openItem3 : false);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Add autoplay option
        autoplaySpeed: 4000, // Specify autoplay speed in milliseconds (e.g., 2000 for 2 seconds)        
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Button
                className={`scroll-to-top ${isVisible ? 'visible' : 'hidden'}`}
                onClick={scrollToTop}
            >
                <FaArrowTurnUp />
            </Button>
            <header className="page">
                <div className="page_breadcrumbs">
                    <div className="container">
                        <ul className="page_breadcrumbs-list d-flex flex-wrap align-items-center p-0 m-0">
                            <li className="list-item">
                                <Link to="/" className="nav-item">Home</Link>
                            </li>

                            <li className="list-item">
                                <Link to="/about" className="nav-item">About</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="page_main">
                    <div className="underlay"></div>
                    <div className="container">
                        <div className="content-wrapper">
                            <h1 className="page_main-header">Welcome to Whole Square: Accelerating Academic Success for Every Student</h1>
                            <p className="page_main-text">
                                At Whole Square, we take pride in our extensive experience of over a decade in providing quality
                                education to school kids. For more than ten years, we have been dedicated to supporting students
                                of all grades in their academic journey, helping them achieve their goals and reach their full
                                potential.
                            </p>
                        </div>
                    </div>
                </div>
            </header>
            <main>
                {/* journey-section */}
                <div className="journey-section py-5">
                    <div className="underlay"></div>
                    <div className="container">
                        <div className="content-wrapper">
                            <h1 className="page_main-header text-center">Our Journey</h1>
                            <Row className='d-md-flex justify-content-center flex-wrap p-0"'>
                                <div className="col-md-4">
                                    <div className="journey-card mb-5">
                                        <div className="journey-content">
                                            <p className='journey-detail'>Four Years of Excellence: For the past four years, Whole Square has been at the forefront of
                                                education, empowering students with the knowledge and skills they need to succeed in school and
                                                beyond.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="journey-card mb-5">
                                        <div className="journey-content">
                                            <p className='journey-detail'>Proven Track Record: With years of experience in the education industry, we have developed a
                                                proven track record of helping students improve their grades, gain confidence, and excel
                                                academically.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="journey-card mb-5">
                                        <div className="journey-content">
                                            <p className='journey-detail'>Continuous Growth: Over the years, we have continuously evolved and adapted our programs to
                                                meet the changing needs of students and educators. We remain committed to staying ahead of
                                                the curve and providing innovative solutions to support student learning.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
                {/* feature-section */}
                <Feature />

                {/* block-section */}
                <section className="infoblock d-flex flex-column flex-lg-row flex-md-wrap">
                    <div className="infoblock_block col-lg-6 d-flex flex-column justify-content-center align-items-center" data-order="1">
                        <div className="content">
                            <h2 className="infoblock_block-header h1 text-white">Why Choose Whole Square:</h2>
                            <ListGroup style={{ backgroundColor: "transparent" }}>
                                {items.map((item, index) => (
                                    <ListGroup.Item key={index} className='list-content'>
                                        {/* Render the number and the item */}
                                        {index + 1}. {item}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </div>
                    <div className="infoblock_block col-lg-6" data-order="2">
                        <div className="img">
                            <picture>
                                <img src={aboutimage} />
                            </picture>
                        </div>
                    </div>
                    <div className="infoblock_block col-lg-6" data-order="2">
                        <div className="img">
                            <picture>
                                <img src={classimage} />
                            </picture>
                        </div>
                    </div>
                    <div className="infoblock_block col-lg-6 d-flex flex-column justify-content-center align-items-center" data-order="1">
                        <div className="content">
                            <h2 className="infoblock_block-header h1 text-white">Class Structure and Support:</h2>
                            <ListGroup style={{ backgroundColor: "transparent" }}>
                                {item.map((item, index) => (
                                    <ListGroup.Item key={index} className='list-content'>
                                        {/* Render the number and the item */}
                                        {index + 1}. {item}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </div>
                </section>
                {/* reviews-section */}
                <section className="reviews" id="reviews">
                    <Container>
                        <h2 className="reviews_header">Our happy parents</h2>
                        <i className="icon-quote-left-solid icon"></i>
                        <Slider {...settings}>
                            <div>
                                <div className="reviews_slider-slide swiper-slide">
                                    <q className="quote">
                                        The teaching here is effective and informative. You have made huge impact in my son, Sabesh’s
                                        Math skill.The teachers have a strong passion for teaching; it is inspiring to students also.
                                        Sabesh really enjoyed the online class. It was well planned and layed out, easy for him to follow.
                                        The work load was just enough, so he could finish everything with enough time, learn about the
                                        topics and not feel over loaded and rushed.
                                    </q>
                                    <span className="name h5"> Abi Ganesan </span>
                                    <div className="author d-flex flex-column align-items-center">
                                        <ul className="rating d-flex align-items-center p-0">
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="reviews_slider-slide swiper-slide">
                                    <q className="quote">
                                    Your team is  highly committed towards your teaching job and ensure that the students under you get individual attention and support.It is also proved by the upward trend of my daughter in her academic performance from semester 1 to semester 2. This team is very approachable and make the students very comfortable with their doubts and clarifications.I really thank you for your efforts and  the way you guide your students under your mentorship.
                                    </q>
                                    <span className="name h5">Rachna gosh</span>
                                    <div className="author d-flex flex-column align-items-center">
                                        <ul className="rating d-flex align-items-center p-0">
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                            <li className="rating_star">
                                                <i className="icon-star icon"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </Container>
                </section>

                {/* faq section */}
                <FAQ />
            </main>
        </>
    )
}

export default About
