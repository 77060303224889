import React from "react"
import ReactDOM from "react-dom/client"
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom"
import "../src/assest/css/index.min.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../src/assest/css/index.css"
import MainWrapper from "./component/MainWrapper/MainWrapper"
import Landing from "./component/LandingPage/Landing"
import About from "./component/About/About"
import Price from "./component/Price/Price"
import DefaultAnimation from "./component/Animation/Animation"
import Contact from "./component/Contact/Contact"
import Blog from "./component/Blog/Blog"
import FAQ from "./component/Faq/faq"
import SingleBlog1 from "./component/Blog/SingleBlog1"
import SingleBlog2 from "./component/Blog/SecongBlog"
import CoursesCard from "./component/CoursesCard/Card"
function App (){
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <></>,
      element: <MainWrapper/>,
      children: [
        {
          index: true,
          element: <Landing/>
        },
        {
          path: "about",
          element: <About/>
        },
        {
          path: "price",
          element: <Price/>
        },
        {
          path: "contact",
          element: <Contact/>
        },
        {
          path: "blog",
          element: <Blog/>
        },
        {
          path: "singleblog1",
          element: <SingleBlog1/>
        },
        {
          path: "faq",
          element: <FAQ/>
        },
        {
          path: "singleblog2",
          element: <SingleBlog2/>
        },
        {
          path: "coursescard",
          element: <CoursesCard/>
        },
      ]
    }
])
return (
  <RouterProvider router={router} />
)
}
export default App
