import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import "../../assest/css/index.css"
import "../../assest/css/index.min.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import logo from "../../assest/img/logo.png"
import logoimage from "../../assest/img/Logo.png"
import { FaWhatsapp } from "react-icons/fa";
interface signUpProps { }
const Footer = (props: signUpProps) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top on route change
    }, [location]);

    return (
        <>
            <footer className="footer">
                <Container>
                    <footer className="footer">
                        <div className="container">
                            <div className="footer_wrapper d-sm-flex flex-wrap flex-lg-nowrap justify-content-lg-between">
                                <div className="footer_block col-sm-6 col-lg-auto">
                                    <div className="logo logo--footer">
                                        <a className="d-inline-flex align-items-center" href="index.html">
                                            <span className="logo_picture">
                                                <img src={logoimage} className="logo-image" />
                                            </span>
                                        </a>
                                    </div>
                                    <p className="footer_block-text">
                                        At Whole Square, we boast over a decade <br />of invaluable experience <br />in delivering top-notch<br /> education to school children.</p>
                                    <ul className="footer_block-socials d-flex align-items-center p-0">
                                        <li className="footer_block-socials_item">
                                            <a className="link" href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                                <i className="icon-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="footer_block-socials_item">
                                            <a className="link" href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                                <i className="icon-twitter"></i>
                                            </a>
                                        </li>
                                        <li className="footer_block-socials_item">
                                            <a className="link" href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                                <i className="icon-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="wrapper d-flex flex-column">
                                        <a className="link link--contacts text text--sm d-inline-flex align-items-center" href=" wholesquare.in@gmail.com">
                                            <i className="icon-envelope icon"></i>
                                            wholesquare.in@gmail.com
                                        </a>
                                        <a className="link link--contacts text text--sm d-inline-flex align-items-center" href="tel:+91 82787 17982">
                                            <FaWhatsapp className="icon-phone-solid icon" />
                                            +91 82787 17982
                                        </a>
                                    </div>
                                </div>
                                <div className="footer_block col-sm-6 col-lg-auto" data-order="2">
                                    <h5 className="footer_block-header">Information:</h5>
                                    <ul className="footer_block-nav p-0 m-0">
                                        <li className="footer_block-nav_item">
                                            <Link to="/about" className="link">
                                                About
                                            </Link>
                                        </li>
                                        <li className="footer_block-nav_item">
                                            <Link to="/contact" className="link">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className="footer_block-nav_item">
                                            <Link to="/blog" className="link">
                                                Blog
                                            </Link>
                                        </li>
                                        <li className="footer_block-nav_item">
                                            <Link to="/price" className="link">
                                                Prices
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer_block col-sm-6 col-lg-auto m-sm-0">
                                    <h5 className="footer_block-header">Popular Courses:</h5>
                                    <ul className="footer_block-list p-0 m-0">
                                        <li className="footer_block-list_item d-flex align-items-baseline">
                                            <span className="marker"></span>
                                            <a className="link" href="#">Math</a>
                                        </li>
                                        <li className="footer_block-list_item d-flex align-items-baseline">
                                            <span className="marker"></span>
                                            <a className="link" href="#">Science</a>
                                        </li>
                                        <li className="footer_block-list_item d-flex align-items-baseline">
                                            <span className="marker"></span>
                                            <a className="link" href="#">Coding</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer_secondary">
                            <div className="container d-flex flex-column flex-sm-row align-items-center justify-content-sm-between">
                                <p className="footer_secondary-copyright">Copyright @ <span id="currentYear"></span> 5bix it Solution</p>
                            </div>
                        </div>
                    </footer>
                </Container>
            </footer>
        </>
    )
}
// Wrap the Footer component with BrowserRouter
const FooterWithRouter = () => (
    <Router>
        <Footer />
    </Router>
);
export default Footer
