
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
export async function sendEmail(): Promise<boolean> {

    try {
        console.log("SENDING MAIL");

        const formData = {
            email: (document.getElementById('contactsEmail') as HTMLInputElement).value,
            name: (document.getElementById('contactsName') as HTMLInputElement).value,
            phoneNumber: (document.getElementById('contactsTel') as HTMLInputElement)?.value,
            subject: (document.getElementById('contactsSubject') as HTMLInputElement).value,
            message: (document.getElementById('contactsMessage') as HTMLTextAreaElement).value
        };

        // Now you can use formData to send the email using EmailJS or any other method
        console.log(formData);
        // console.log(data);
        if (!formData.email || !formData.name || !formData.phoneNumber || !formData.subject || !formData.message) {
            // alert("Please fill out all fields before sending the message.");
            toast.error("Please fill out all fields before sending the message.");
            return false;
        }
        const resp = await emailjs.send("service_nulgm5o","template_5559n1h",
            {
                "message":JSON.stringify(formData),
                "from_name":"wholesquare"
            },
           "nUtovn1WKYLMwLZWi"
        );
        console.log(resp);
        toast.success("Thanks for reaching out! Our team will reach out to you shortly!.");
        // alert("Our team will reach out to you shortly!.");
        (document.getElementById('contactsEmail') as HTMLInputElement).value = '';
        (document.getElementById('contactsName') as HTMLInputElement).value = '';
        (document.getElementById('contactsTel') as HTMLInputElement).value = '';
        (document.getElementById('contactsSubject') as HTMLInputElement).value = '';
        (document.getElementById('contactsMessage') as HTMLTextAreaElement).value = '';
        return true;
    } catch (error) {
        console.error('Error occurred while sending email:', error);
        return false;
    }
}



export async function sendEmail2(): Promise<boolean> {
    try {
        const formData = {
            studentname: (document.getElementById('studentname') as HTMLInputElement).value,
            Parentname: (document.getElementById('Parentname') as HTMLInputElement).value,
            Studentgrade: (document.getElementById('Studentgrade') as HTMLInputElement).value,
            email: (document.getElementById('Email') as HTMLInputElement).value,
            Phonenumber: (document.getElementById('Phonenumber') as HTMLInputElement)?.value,
        };
        console.log (formData)
        if (!formData.email || !formData.studentname || !formData.Parentname || !formData.Studentgrade || !formData.Phonenumber) {
            toast.error("Please fill out all fields before sending the message.");
            return false;
        }
        const resp = await emailjs.send("service_nulgm5o","template_5559n1h",
            {
                "message":JSON.stringify(formData),
                "from_name":"wholesquare"
            },
           "nUtovn1WKYLMwLZWi"
        );
        console.log(resp);
        toast.success("Thanks for reaching out! Our team will reach out to you shortly!.");
        (document.getElementById('studentname') as HTMLInputElement).value = '';
        (document.getElementById('Parentname') as HTMLInputElement).value = '';
        (document.getElementById('Studentgrade') as HTMLInputElement).value = '';
        (document.getElementById('Email') as HTMLInputElement).value = '';
        (document.getElementById('Phonenumber') as HTMLTextAreaElement).value = '';
        return true;
    } catch (error) {
        console.error('Error occurred while sending email:', error);
        return false;
    }
}