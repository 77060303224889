import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button } from "react-bootstrap"
import "../../assest/css/index.css"
import "../../assest/css/index.min.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import cardimage from "../../assest/img/dummy-image.webp"
import bannerimage from "../../assest/img/banner-image.webp"
import Feature from "../FeatureCard/Feature"
import { useLottie } from "lottie-react";
import * as scene from "../../assest/animations/scene.json";
import * as wave from "../../assest/animations/wave.json"
import * as hero from "../../assest/animations/hero.json"
import DefaultAnimation from "../Animation/Animation"
import { Link } from "react-router-dom"
import { FaArrowTurnUp } from "react-icons/fa6";
import usp1 from "../../assest/img/USP 1.png"
import usp2 from "../../assest/img/USP2.png"
import usp3 from "../../assest/img/USP3.png"
import usp4 from "../../assest/img/USP4.png"
import usp5 from "../../assest/img/USP5.png"
import { TbMathSymbols } from "react-icons/tb";
import { MdOutlineScience } from "react-icons/md";
import { GrPersonalComputer } from "react-icons/gr";
import smallclass from "../../assest/img/icon1.png"
import makeclass from "../../assest/img/icon2.png"
import { FaCircle } from "react-icons/fa";
import icon2 from "../../assest/img/icon2.png"
import icon3 from "../../assest/img/icon3.png"
import icon4 from "../../assest/img/icon4.png"
import icon5 from "../../assest/img/icon5.png"
import icon6 from "../../assest/img/icon6.png"
interface signUpProps { }
const CoursesCard = (props: signUpProps) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Button
                className={`scroll-to-top ${isVisible ? 'visible' : 'hidden'}`}
                onClick={scrollToTop}
            >
                <FaArrowTurnUp />
            </Button>
            <main>
                {/* hero-section-start */}
                <div className="underlay"></div>

                {/* courses-section */}
                <section className='Program'>
                    <Container >
                        <div className="popular_header">
                            <h2 className="popular_header-title">Popular courses</h2>
                            <p className="popular_header-text">
                                "Unlock your creativity and conquer the blank page with our popular writing course. From crafting compelling narratives to honing your style, discover the writer within you."
                            </p>
                        </div>
                        <ul className="popular_list d-md-flex flex-wrap p-0">
                            <li className="popular_list-card course-card col-12 col-md-6 col-lg-4">
                                <div className="course-card_wrapper">
                                    <div className="top d-flex align-items-start">
                                        <span className="top_icon top_icon--blue">
                                            <TbMathSymbols className="icon-code-solid icon" />
                                        </span>
                                        <div className="wrapper d-flex flex-column">
                                            <h5 className="top_title">Math Program</h5>
                                            <ul className="rating d-flex align-items-center p-0">
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <ul className="bottom_list">
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    2 classes per week
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    55 minutes class
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    30 minutes Doubt Session per week
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Grade Levels: K-12
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Teaching experts provided
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Customized homework and assignments
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Preparation for  competitive exams
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className="popular_list-card course-card col-12 col-md-6 col-lg-4">
                                <div className="course-card_wrapper">
                                    <div className="top d-flex align-items-start">
                                        <span className="top_icon top_icon--orange">
                                            <GrPersonalComputer className="icon-css3 icon" />
                                        </span>
                                        <div className="wrapper d-flex flex-column">
                                            <h5 className="top_title">Coding Program</h5>
                                            <ul className="rating d-flex align-items-center p-0">
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <ul className="bottom_list">
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    2 classes per week
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    55 minutes class
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    30 minutes Doubt Session per week
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Grade Levels: K-12
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Teaching experts provided
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Customized homework and assignments
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Preparation for  competitive exams
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className="popular_list-card course-card col-12 col-md-6 col-lg-4">
                                <div className="course-card_wrapper">
                                    <div className="top d-flex align-items-start">
                                        <span className="top_icon top_icon--orange">
                                            <MdOutlineScience className="icon-css3 icon" />
                                        </span>
                                        <div className="wrapper d-flex flex-column">
                                            <h5 className="top_title">Science Program</h5>
                                            <ul className="rating d-flex align-items-center p-0">
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                                <li className="rating_star">
                                                    <i className="icon-star icon"></i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <ul className="bottom_list">
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    2 classes per week
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    55 minutes class
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    30 minutes Doubt Session per week
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Grade Levels: K-12
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Teaching experts provided
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Customized homework and assignments
                                                </p>
                                            </li>
                                            <li className="bottom_list-item d-flex align-items-baseline">
                                                <FaCircle className='me-2' style={{ width: "14px" }} />
                                                <p className="bottom_list-item_text">
                                                    Preparation for  competitive exams
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </Container>
                </section>
            </main>
            {/* footer */}
        </>
    )
}

export default CoursesCard
