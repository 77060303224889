import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button } from "react-bootstrap"
import "../../assest/css/index.css"
import "../../assest/css/index.min.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import cardimage from "../../assest/img/dummy-image.webp"
import bannerimage from "../../assest/img/banner-image.webp"
import Feature from "../FeatureCard/Feature"
import { useLottie } from "lottie-react";
import * as scene from "../../assest/animations/scene.json";
import * as wave from "../../assest/animations/wave.json"
import * as hero from "../../assest/animations/hero.json"
import DefaultAnimation from "../Animation/Animation"
import { Link } from "react-router-dom"
import { FaArrowTurnUp } from "react-icons/fa6";
import usp1 from "../../assest/img/USP 1.png"
import usp2 from "../../assest/img/USP2.png"
import usp3 from "../../assest/img/USP3.png"
import usp4 from "../../assest/img/USP4.png"
import usp5 from "../../assest/img/USP5.png"
import { TbMathSymbols } from "react-icons/tb";
import { MdOutlineScience } from "react-icons/md";
import { GrPersonalComputer } from "react-icons/gr";
import smallclass from "../../assest/img/icon1.png"
import makeclass from "../../assest/img/icon2.png"
import { FaCircle } from "react-icons/fa";
import icon2 from "../../assest/img/icon2.png"
import icon3 from "../../assest/img/icon3.png"
import icon4 from "../../assest/img/icon4.png"
import icon5 from "../../assest/img/icon5.png"
import icon6 from "../../assest/img/icon6.png"
import PricingCard from '../CoursesCard/Card';
import infologo from "../../assest/img/Logo.png"
interface signUpProps { }
const Landing = (props: signUpProps) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Button
                className={`scroll-to-top ${isVisible ? 'visible' : 'hidden'}`}
                onClick={scrollToTop}
            >
                <FaArrowTurnUp />
            </Button>
            <main>
                {/* hero-section-start */}
                <div className="underlay"></div>
                <section className="hero">
                    <Container>
                        <div className="hero_content">
                            <h1 className="hero_content-header">Interactive Live Online Classes catered to Kids!</h1>
                            <p className="hero_content-text">
                                Welcome to Whole Square Learning, where every kid&#39;s academic journey is elevated! Our
                                innovative online platform is dedicated to providing interactive classes designed to empower kids
                                in boosting their grades and accelerating their learning potential.
                            </p>
                        </div>
                        <div className="hero_media">
                            <div className="toggle-btn-container">
                                <DefaultAnimation
                                    animData={hero}
                                    width={100}
                                />
                            </div>
                        </div>
                    </Container>
                </section>

                {/* feature-section-start */}
                <Feature />
                {/* promo-section-start */}
                <section className="promo">
                    <Container className="d-flex flex-column-reverse flex-lg-row justify-content-lg-end">
                        <div className="promo_media">
                            <div className="toggle-btn-container">
                                <DefaultAnimation
                                    animData={scene}
                                    width={100}
                                />
                            </div>
                        </div>
                        <div className="promo_content">
                            <h2 className="promo_content-header">Learn with Whole Square!</h2>
                            <p className="promo_content-text">
                                Discover academic excellence at Whole Square, where a team of experienced teachers from India awaits to guide you through all subjects. From mathematics to coding to science, our educators bring years of expertise and passion to ensure personalized learning tailored to your needs. From personalized teaching methods to tailored support, we empower learners to excel in their academic journey. Join us and unlock your full potential with our <b>esteemed team of educators from India.</b>
                            </p>
                            {/* <a className="promo_content-btn btn btn--gradient" href="#">
                                <span className="text">Try for Free</span>
                            </a> */}
                        </div>
                    </Container>
                </section>

                {/* acout-section */}
                <section className="about">
                    <Container>
                        <div className="about_deco">
                            <div className="toggle-btn-container">
                                <DefaultAnimation
                                    animData={wave}
                                    width={100}
                                />
                            </div>
                        </div>
                        <div className="about_main">
                            <div className="info-circle">
                                <div className="outer-circle">
                                    <div className="inner-circle">
                                        <img src={infologo} className="image text-center"/>
                                    </div>
                                </div>
                                <div className="info-blocks">
                                    <div className="info-block clearfix">
                                        <div className="info-icon">
                                            <img src={smallclass} />
                                        </div>
                                        <div className="info-text">
                                            <h5 className="title text-upper">Small Class Size</h5>
                                        </div>
                                    </div>
                                    <div className="info-block clearfix">
                                        <div className="info-icon">
                                            <img src={icon2} />
                                        </div>
                                        <div className="info-text">
                                            <h5 className="title text-upper">Acceleration In Grades</h5>
                                        </div>
                                    </div>
                                    <div className="info-block clearfix">
                                        <div className="info-icon">
                                            <img src={icon3} />
                                        </div>
                                        <div className="info-text">
                                            <h5 className="title">Free Make-up Classes</h5>
                                        </div>
                                    </div>
                                    <div className="info-block clearfix">
                                        <div className="info-icon">
                                            <img src={icon4} />
                                        </div>
                                        <div className="info-text">
                                            <h5 className="title">Customized Homework</h5>
                                        </div>
                                    </div>
                                    <div className="info-block clearfix">
                                        <div className="info-icon">
                                            <img src={icon5} />
                                        </div>
                                        <div className="info-text title-only">
                                            <h5 className="title text-upper">1:1 Parent Teacher Meeting</h5>
                                        </div>
                                    </div>
                                    <div className="info-block clearfix">
                                        <div className="info-icon">
                                            <img src={icon6} />
                                        </div>
                                        <div className="info-text title-only">
                                            <h5 className="title text-upper">Preparing For Competitive Exams</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                {/* courses-section */}
                <PricingCard />
                {/* banner-section */}
                <div className="banner">
                    <div className="underlay"></div>
                    <Container className="d-lg-flex align-items-center">
                        <div className
                            ="banner_content">
                            <h4 className="banner_content-title">
                                Unlimited access to educational materials and lectures
                            </h4>
                            {/* <div className="wrapper">
                                <a className="banner_content-btn btn btn--yellow" href="pricing.html">See pricing plans</a>
                            </div> */}
                        </div>
                        <div className="banner_media">
                            <picture>
                                <source data-srcset="img/placeholder.jpg" />
                                <img src={bannerimage} />
                            </picture>
                        </div>
                    </Container>
                </div>
            </main>
            {/* footer */}
        </>
    )
}

export default Landing
