import React, { useState } from "react";
import { Container, Col, Row, Navbar, Nav, Modal, Button, NavDropdown } from "react-bootstrap"
import "../../assest/css/index.css"
import "../../assest/css/index.min.css"
import "../../assest/css/preload.min.css"
import "../../assest/css/icomoon.css"
import "../../assest/css/libs.min.css"
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import logo from "../../assest/img/logo.png"
import { Link } from "react-router-dom"
import { Form } from "react-bootstrap";
import logoimage from "../../assest/img/Logo.png"
import { sendEmail2 } from "../node-mail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
interface signUpProps { }
const Navigation = (props: signUpProps) => {
    const [phone, setPhone] = useState("");
    const [isChecked, setIsChecked] = React.useState(true); // or false if unchecked by default
    const [expanded, setExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [parentname, setparentName] = useState('');
    const [dataProcessingChecked, setDataProcessingChecked] = useState(true);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Handle form submission logic here
    };
    return (
        <>
            <div className="promobar d-flex align-items-center">
                <Container className="d-flex align-items-center justify-content-between">
                    <ul className="promobar_socials d-flex align-items-center m-0 p-0">
                        <li className="promobar_socials-item">
                            <FaFacebook />
                        </li>
                        <li className="promobar_socials-item">
                            <FaTwitter />
                        </li>
                        <li className="promobar_socials-item">
                            <FaInstagram />
                        </li>
                    </ul>
                    <div className="promobar_main d-flex align-items-center">
                        <p className="promobar_main-text">Book Free Classes  <span className="hide">     For a Week</span></p>
                        <Button className="btn btn--yellow" onClick={handleShowModal}>
                            <span>Book Now</span>
                        </Button>
                        <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-50w">
                            <Modal.Header closeButton style={{ border: "none" }}>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                <p className="title mb-3">Our team will contact you soon for free trails!</p>
                                <Container>
                                    <Row className="justify-content-center">
                                        <Col md={10}>
                                            <Form >
                                                <div className="w-100 mb-3">
                                                    <Form.Group controlId="studentname">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Student name"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="w-100 mb-3">
                                                    <Form.Group controlId="Parentname">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Parent name"
                                                            value={parentname}
                                                            onChange={(e) => setparentName(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="w-100 mb-3">
                                                    <Form.Group controlId="Studentgrade">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Student grade"
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="w-100 mb-3">
                                                    <Form.Group controlId="Email">
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="w-100 mb-3">
                                                <input type='text' className='d-none' value={phone} id='Phonenumber'/>
                                                    <PhoneInput
                                                        country={"eg"}
                                                        // enableSearch={true}
                                                        value={phone}
                                                        onChange={(country, e, phone) => {
                                                            setPhone(phone?.target.value)}}
                                                        data-type="tel"
                                                        placeholder="Phone number"
                                                    />
                                                </div>
                                                <Button className="btn btn--gradient mt-3" onClick={sendEmail2}>
                                                    <span className="text w-100">Book My Slot</span>
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Modal>
                    </div>
                </Container>
            </div>
            <Navbar expand="lg" className="header" data-page="home">
                <Container className="d-flex flex-wrap justify-content-between align-items-center bg-white">
                    <Navbar.Brand>
                        <Link to="/" className="d-inline-flex align-items-center">
                            <img src={logoimage} className="logo-image" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className={`${expanded ? 'show' : ''}`}>
                        <Nav className="ms-auto text-center">
                            <Nav.Link as={Link} to="/" className="nav-item me-3">Home</Nav.Link>
                            <Nav.Link as={Link} to="/about" className="nav-item me-3">About Us</Nav.Link>
                            <Nav.Link as={Link} to="/price" className="nav-item me-3">Prices</Nav.Link>
                            <Nav.Link as={Link} to="/coursescard" className="nav-item me-3">Program</Nav.Link>
                            <Nav.Link as={Link} to="/blog" className="nav-item me-3">Blog</Nav.Link>
                            <Nav.Link as={Link} to="/contact" className="nav-item me-3">Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navigation
